import React from "react"
import { Row, Col } from "react-flexbox-grid"
import { graphql } from "gatsby"
import "./index.scss"
import Hero from "../../../components/Hero"
import ContentBlock from "../../../components/ContentBlock"
import HelmetWrapper from "../../../components/HelmetWrapper"
import CalloutBlock from "../../../components/CalloutBlock"
import Layout from "../../../components/Layout"

import grantImg from "../../../images/sm_circle_GrantProgram3.png";
import headerHero from "../../../images/patientAdvocacyHero.png";
import arrow from "../../../assets/images/chevron-circle-right-solid.svg"
import { GrantPrograms } from "../../../components/GrantPrograms"

const pageTitle = "External Funding | Harmony Biosciences"
const pageDescription =
  "We support external funding for charitable contributions, corporate memberships and sponsorships and non-medical education grants. Learn more."
const pageKeywords = ""
const smallScreenSize = () => {
  const _screenSize = window ? window.screen.width : 0
  return _screenSize <= 768 ? 66 : 155
}

class grantPrograms extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      offsetSize: 0,
    }
  }
  componentDidMount() {
    this.setState({
      offsetSize: smallScreenSize(),
    })
  }
  render() {
    const pageContent = this.props.data.contentfulGrantProgram
    return (
      <Layout jobCode="US-HAR-2200051/Jul 2022">
        <div id="patient-advocacy-grants">
          <HelmetWrapper
            pageTitle={pageTitle}
            pageDescription={pageDescription}
            pageKeywords={pageKeywords}
          />

          <Hero bg={headerHero}>
            <h1>
              <strong>External Funding</strong>
            </h1>
          </Hero>
          <ContentBlock id="patient-advocacy-grants" color="orange">
            <Row>
              <Col xs={12} md={2}>
                <Row center="xs">
                  <Col>
                    <img src={grantImg} className="" />
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={10}>
                <h2>External Funding Support</h2>
                <p>
                  <strong>Harmony Biosciences</strong> is committed to
                  providing external funding support for the following requests:
                </p>
                <h3>Charitable Contributions</h3>
                <p>
                  We provide charitable contributions to non-profit
                  organizations when the request for support relates to: our
                  corporate social responsibility strategy; supporting community
                  activities/programs; or improving patient care in one of
                  Harmony’s therapeutic areas of interest. Support is provided in response
                  to an unsolicited written request made by a non-profit
                  organization where Harmony does not receive a tangible benefit
                  in return from the requesting organization.{" "}
                </p>
                <h3>Corporate Memberships</h3>
                <p>
                  We provide corporate membership support to for-profit or
                  non-profit healthcare/patient organizations for the purpose of
                  furthering the mission of the organization and not for the
                  purpose of promoting Harmony products. Personal services may
                  include Harmony employees attending organizational
                  meetings/events or participation on organizational committees,
                  boards, or other formal meetings for the purpose of assisting
                  the organization in achieving its mission and goals.
                </p>
                <h3>Corporate Sponsorships</h3>
                <p>
                  We provide corporate sponsorships to support business,
                  healthcare, and patient organizations’
                  events/activities/programs for which Harmony may be offered a
                  tangible benefit from the organization receiving the funding.
                </p>

                <h3>Non-Medical Education Grants</h3>
                <p>
                  We provide funding to medical, scientific, or patient
                  organizations to support advocacy and education/patient care
                  activities, and such funding does not support HCP medical
                  education (CME or non-CME). Non-medical education grants are
                  provided for specific educational programs/initiatives that
                  further the mission of a healthcare or patient organization
                  that advocates for and/or addresses the needs of patients with
                  rare diseases and their caregivers.
                </p>
                <p>
                  The funding requests above are reviewed by the Harmony
                  External Funding Review Committee. Applicants will be notified
                  of funding decisions within 45 days. Harmony Biosciences
                  receives many worthwhile requests. Unfortunately, we are
                  unable to fund them all. It is important to note that past
                  support does not guarantee future approval. In addition,
                  submissions can be approved at any amount or quantity that is
                  less than the requested amount.
                </p>
                <p>
                  All funds are paid directly to the requesting organization,
                  and never to an individual.
                </p>
                <h2 style={{ color: "#636569" }}>
                  TO APPLY FOR FUNDING ON THE PORTAL,{" "}CLICK&nbsp;
                  <a
                    target="_blank"
                    className="bold"
                    href="https://id.medcompli.com/Account/ExternalRegister/harmony"
                  >
                    HERE
                  </a>
                  .
                </h2>
                <p>
                  Should you have any questions about portal navigation or
                  specific questions about support applications, please refer to
                  the following email addresses:
                </p>
                <p>
                  <ul style={{ color: "#61A60E", paddingLeft: "15px" }}>
                    <li style={{ paddingBottom: "10px" }}>
                      <span style={{ color: "#636569" }}>
                        Charitable contributions –&nbsp;
                      </span>
                      <a
                        href="mailto:philanthropy@harmonybiosciences.com"
                        target="_blank"
                      >
                        philanthropy@harmonybiosciences.com
                      </a>
                    </li>
                    <li>
                      {" "}
                      <span style={{ color: "#636569" }}>
                        Corporate memberships, sponsorships, or non-medical
                        education grants –{" "}
                      </span>
                      <a
                        href="mailto:advocacy@harmonybiosciences.com"
                        target="_blank"
                      >
                        advocacy@harmonybiosciences.com
                      </a>
                    </li>
                  </ul>
                </p>
              </Col>
            </Row>
          </ContentBlock>

          <ContentBlock color="white" className="last">
            <Row className={"callout-row"}>
              <Col xs={12} md={6}>
                <CalloutBlock
                  className="text-only bg-blue"
                  link="/funding-programs/patients-at-the-heart/"
                >
                  <h2>
                    <strong>PATIENTS AT THE HEART</strong>
                  </h2>
                  <img className="callout-arrow" src={arrow} />
                </CalloutBlock>
              </Col>
              <Col xs={12} md={6}>
                <CalloutBlock
                  className="text-only bg-rose"
                  link="/funding-programs/progress-at-the-heart/"
                >
                  <h2>
                    <strong>PROGRESS AT THE HEART</strong>
                  </h2>
                  <img className="callout-arrow" src={arrow} />
                </CalloutBlock>
              </Col>
            </Row>
            <Row className="callout-bottom">
              <Col xs={12} md={6}>
                <CalloutBlock
                  className="text-only bg-green"
                  link="/funding-programs/medical-education-grants"
                >
                  <h2>
                    <strong>MEDICAL EDUCATION GRANTS</strong>
                  </h2>
                  <img className="callout-arrow" src={arrow} />
                </CalloutBlock>
              </Col>
              <Col xs={12} md={6}>
                <CalloutBlock
                  className="text-only bg-gray"
                  link="/funding-programs/investigator-sponsored-studies"
                >
                  <h2>
                    <strong>INVESTIGATOR SPONSORED STUDIES</strong>
                  </h2>
                  <img className="callout-arrow" src={arrow} />
                </CalloutBlock>
              </Col>
            </Row>
          </ContentBlock>
        </div>
      </Layout>
    )
  }
}

export default grantPrograms

export const pageQuery = graphql`
  query {
    contentfulGrantProgram(slug: { eq: "patient-advocacy-grants" }) {
      id
      slug
      pageTitle
      heroImage {
        gatsbyImageData
      }
      container {
        backgroundColor
        className
        containerId
        title {
          title
        }
        body {
          ... on ContentfulParagraph {
            id
            name
            body {
              raw
            }
          }
          ... on ContentfulAccordion {
            title {
              title
            }
            body {
              raw
            }
          }
        }
        thumbnail {
          gatsbyImageData
        }
      }
      footer {
        paragraph {
          body {
            raw
          }
        }
      }
      buttons {
        backgroundColor
        link
        label
      }
    }
  }
`
